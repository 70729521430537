/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from '@emotion/styled'
import { Parallax } from "react-spring/renderprops-addons.cjs"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import SVG from "@lekoarts/gatsby-theme-cara/src/components/svg"
import { UpDown, UpDownWide } from "@lekoarts/gatsby-theme-cara/src/styles/animations"
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout"
import Sidebar from "@lekoarts/gatsby-theme-cara/src/components/sidebar"
import SEO from "@lekoarts/gatsby-theme-cara/src/components/seo"
import React, { useState, useEffect }  from "react"
import { window, exists } from "browser-monads"
import { navigate } from 'gatsby-link'
import axios from "axios"
import { Link } from "gatsby"
import downloadFile from './berna.pdf' 
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from "gatsby"
import CountUp from 'react-countup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressBook, faPhoneSquareAlt, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import Sitemap from "@lekoarts/gatsby-theme-cara/src/components/sitemap"

const Input = styled.input` 
	background: #242b3c;
    border: 0px;
    font-size: 1em;
    padding:10px;
    margin:5px;
    color:white;
    width:100%;
`

const InputRadio = styled.input` 
  background: #242b3c;
    border: 0px;
    font-size: 1em;
    padding:10px;
    margin:5px;
    color:white;
    width:40px;
`


const SubmitButton = styled.button`
	border: 0px;
    padding: 10px;
    background: #3caf7b;
    color: white;
    font-size: 1em;
    font-weight: 800;
`    

const CatalogButton = styled.a`
    background: chocolate;
    padding: 10px;
    display: inline-block;
    border-radius: 2px;
    border-bottom: 4px solid #86460b;
    cursor: pointer;
    font-size:1.3em;
    color:white;
    margin-bottom:20px;
`

const LongArrowLeft = styled.div`
  display: block;
  margin: 30px auto;
  width: 25px;
  height: 25px;
  border-top: 2px solid gray;
  border-left: 2px solid gray ;
  transform: rotate(-45deg);
     &:after{
    content: "";
    display: block;
    width: 2px;
    height: 45px;
    background-color: gray;
    transform: rotate(-45deg) translate(15px, 4px);
    left: 0;
    top: 0;
  }
`
const ImageWrapper = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  align-items:center;
  align-content:space-around;
  flex-direction:row;
  background:white;
  padding:10px;
  border-radius:3px;
  margin-top:40px;
`

const CounterWrapper = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  align-items:center;
  align-content:space-around;
  flex-direction:row;
  padding:10px;
  border-radius:3px;
  margin-top:40px;
  background:#154084;
  box-shadow: 2px 7px 10px #000000;  
`

const CounterBlock = styled.div`
  
  padding: 10px;
  text-align: center;
  border-radius: 3px;
    
`




const promo_games = () =>{
   
   useEffect(() => {
        window.scrollTo(0,0)
    })

  const data = useStaticQuery(graphql`
query {
  ttmlogo: file(relativePath: {eq: "ttm-horiz.png"}) {
    childImageSharp {
      fluid(maxWidth:170){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  casinotrac: file(relativePath: {eq: "promotions/casinotrac.png"}) {
    childImageSharp {
      fluid(maxWidth:400){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  promo1: file(relativePath: {eq: "promotions/promogames12.jpeg"}) {
    childImageSharp {
      fluid(maxWidth:900){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  promo2: file(relativePath: {eq: "promotions/promogames1.jpeg"}) {
    childImageSharp {
      fluid(maxWidth:900){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  }
  firekeepers: file(relativePath: {eq: "promotions/firekeepers.png"}) {
    childImageSharp {
      fluid(maxWidth:150){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  redmile: file(relativePath: {eq: "promotions/red-mile-logo.png"}) {
    childImageSharp {
      fluid(maxWidth:90){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  cherae: file(relativePath: {eq: "promotions/Cherae.jpeg"}) {
    childImageSharp {
      fluid(maxWidth:90){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  coushata: file(relativePath: {eq: "promotions/coushata.jpg"}) {
    childImageSharp {
      fluid(maxWidth:90){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  fsgn: file(relativePath: {eq: "promotions/fsgn.png"}) {
    childImageSharp {
      fluid(maxWidth:90){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  g2e: file(relativePath: {eq: "promotions/g2e.png"}) {
    childImageSharp {
      fluid(maxWidth:90){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  grandtreasures: file(relativePath: {eq: "promotions/grandtreasures.png"}) {
    childImageSharp {
      fluid(maxWidth:90){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  jfs: file(relativePath: {eq: "promotions/JFS.jpg"}) {
    childImageSharp {
      fluid(maxWidth:90){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  livecasino: file(relativePath: {eq: "promotions/livecasino.jpg"}) {
    childImageSharp {
      fluid(maxWidth:90){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  luckyeagle: file(relativePath: {eq: "promotions/luckyeagle.png"}) {
    childImageSharp {
      fluid(maxWidth:130){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  muckleshoot: file(relativePath: {eq: "promotions/muckleshoot.png"}) {
    childImageSharp {
      fluid(maxWidth:130){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  naskila: file(relativePath: {eq: "promotions/naskila.png"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  palms: file(relativePath: {eq: "promotions/palms.png"}) {
    childImageSharp {
      fluid(maxWidth:90){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  pearlriver: file(relativePath: {eq: "promotions/pearlriver.png"}) {
    childImageSharp {
      fluid(maxWidth:130){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  planethollywood: file(relativePath: {eq: "promotions/planethollywood.png"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  risingstar: file(relativePath: {eq: "promotions/risingstar.png"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  roadhouse: file(relativePath: {eq: "promotions/roadhouse.png"}) {
    childImageSharp {
      fluid(maxWidth:90){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  saganing: file(relativePath: {eq: "promotions/saganing.png"}) {
    childImageSharp {
      fluid(maxWidth:140){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  saratoga: file(relativePath: {eq: "promotions/saratoga.jpg"}) {
    childImageSharp {
      fluid(maxWidth:90){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  seneca: file(relativePath: {eq: "promotions/seneca.png"}) {
    childImageSharp {
      fluid(maxWidth:90){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  soaringeagle: file(relativePath: {eq: "promotions/soaringeagle.jpeg"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  tachi: file(relativePath: {eq: "promotions/tachi.jpg"}) {
    childImageSharp {
      fluid(maxWidth:90){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  turningstone: file(relativePath: {eq: "promotions/turningstone.png"}) {
    childImageSharp {
      fluid(maxWidth:130){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  }

}`
)
 
  const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });
    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        form.reset();
      }
    };
    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });
      axios({
        method: "post",
        url: "https://getform.io/f/33fc9681-937d-40a3-9776-38ff436db3db",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(true, "Thanks!", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };





        return (
   
	  
	 <div id="outer-container">
  <SEO/>
    <Sidebar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
	      <div id="page-wrap">

       
        
        <div sx={{mt:60,p:20,maxWidth:900,m:'0 auto'}}>
        <a href="/">
          <Img sx={{marginTop:'20px'}}
            fluid={data.ttmlogo.childImageSharp.fluid}
            alt="Touch This Media Logo"
          />   
        </a>
  	      <h1 sx={{
  	        color: "heading",
  	        m: 1,
            mt:80,
            mb:40
        
  	      }}>Promotional Games & Contests</h1>

  	     
  	
  	       
           <Img sx={{m:'0 auto'}}
              fluid={data.promo1.childImageSharp.fluid}
              alt="promo game"
            />

	      
        <div>

         <h2>Solution</h2>
			  <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
			  Using Free2Play sports, awards, TV, pick, spin & scratch contests, we give your prospects and clients a reason to come in more often, stay a little longer and spend more.
         </p>

         <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
			  While the provision of in-location,  self-service interactive loyalty kiosk solutions is our speciality, we also provide extensive web and mobile-based solutions.
        </p>


        <CounterWrapper>
          <CounterBlock>
            <h3 sx={{color:`white`}}>CONTESTS <br/> RUN</h3>
            <CountUp sx={{fontSize: [4],mt: 3,color: `white`,}}
            delay={3} 
            end={1360} 
            separator=","
            prefix=""
            duration="4"            
            />
            <span sx={{fontSize: [4],
      mt: 3,
      color: `white`,}}> + </span>
          </CounterBlock>

          <CounterBlock>
            <h3 sx={{color:`white`}}>ENTRIES <br/> ACCEPTED</h3>
            <CountUp sx={{fontSize: [4],mt: 3,color: `white`,}}
            delay={3} 
            end={2600000} 
            separator=","
            prefix="" 
            duration="4"
            />
             <span sx={{fontSize: [4],
      mt: 3,
      color: `white`,}}> + </span>
          </CounterBlock>

          <CounterBlock>
            <h3 sx={{color:`white`}}>PRIZES <br/> AWARDED</h3>
            <CountUp sx={{fontSize: [4],mt: 3,color: `white`,}}
            delay={3} 
            end={1100000} 
            separator=","
            prefix="$ "
            duration="4" />
             <span sx={{fontSize: [4],
      mt: 3,
      color: `white`,}}> + </span>
          </CounterBlock>
        </CounterWrapper>
        

        <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
        Having run thousands of contests, accepted millions of entries & awarded millions of dollars in prizes, we know how to structure promotions that deliver proven return on investment.
        </p>

        <CounterWrapper>
          <CounterBlock>
           
            <CountUp sx={{fontSize: [4],mt: 3,color: `white`,}}
            delay={4} 
            end={9} 
            separator=""
            prefix=""
            duration="4"            
            />
            <span sx={{fontSize: [4],
      mt: 3,
      color: `white`,}}> X </span>
             <h3 sx={{color:`white`}}> ROI DELIVERING <br/> $150,000 IN <br/> ADDITIONAL WIN</h3>

             <h5 sx={{color:`white`}}> Midwest location, 500-1,100 machines</h5>
            
          </CounterBlock>

          <CounterBlock>
            
            <CountUp sx={{fontSize: [4],mt: 3,color: `white`,}}
            delay={4} 
            end={45} 
            separator=","
            prefix="" 
            duration="4"
            />
          <span sx={{fontSize: [4],
      mt: 3,
      color: `white`,}}> % </span>
      <h3 sx={{color:`white`}}>of <br/>NEW CARDED<br/>RETURNED</h3>
      <h5 sx={{color:`white`}}> Midwest location, 500-1,100 machines</h5>
          </CounterBlock>

          <CounterBlock>
           
            <CountUp sx={{fontSize: [4],mt: 3,color: `white`,}}
            delay={4} 
            end={10} 
            separator=""
            prefix=""
            duration="4" />
             <span sx={{fontSize: [4],
      mt: 3,
      color: `white`,}}> % </span>
       <h3 sx={{color:`white`}}>INCREASED <br/> SPEND</h3>
       <h5 sx={{color:`white`}}> Pacific Northwest location, <br/> 1,000+ machines Games</h5>
          </CounterBlock>
        </CounterWrapper>

        <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
          Monthly programs start at $899.00 & include hosting, support and maintenance services.
        </p>

			   <Img
            fluid={data.promo2.childImageSharp.fluid}
            alt="promo game"
          />
			 
		  </div>

		  <div>
		  	<h2>Useful to</h2>
		  	<p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
		  		Casinos, retail, bars and restaurants
		  	</p>

		  </div>

		   <div id="ttm-form" sx={{
        m:"0 auto",
        width:'100%',
        padding:40,
        background:'#191e2b'
       }}>
                
                <CatalogButton href={'/berna.pdf'} download>Get Game Catalog</CatalogButton>
                
                <div>
                    <form name="promo" onSubmit={handleOnSubmit}>
                     
                        <Input
                            type="text"
                            placeholder="name"
                            name="name"
                            required
                           
                             
                        />
                        <br/>
                        <Input
                            type="email"
                            placeholder="email"
                            name="email"
                            required
                            
                              
                            
                        />
                        <br />
                        <Input 
                        	type="tel"
                        	placeholder="phone" 
                        	name="phone" 
                          
                           
                        />
                        <br />
                         <Input 
                        	type="text"
                        	placeholder="company" 
                        	name="company"
                            
                        />
                        <br />
                        <br />
                        <p sx={{color:`white`}}>Follow up with you?</p>
                       	<ul style={{listStyleType:`none`}}>
                       		<li>
                                <InputRadio
                                	required
                                    type="radio"
                                    value="Yes"
                                    name="followup"
                                    
                                     
                                />
                                <label sx={{color:`white`}}>Yes</label>
                                </li>

                               <li>
                                <InputRadio
                                	required
                                    type="radio"
                                    value="No"
                                    name="followup"
                                    
                                     
                                /> 
                                <label sx={{color:`white`}}>No</label>
                                </li>
                        </ul>                                                    
                        <SubmitButton type="submit" disabled={serverState.submitting}> Send </SubmitButton>
                        {serverState.status && (
                        <p className={!serverState.status.ok ? "errorMsg" : ""}>
                        {serverState.status.msg}
                        </p>
                        )}
                    </form>
                </div>
            </div>

             

      <ImageWrapper>
           <Img
            fluid={data.casinotrac.childImageSharp.fluid}
            alt="casino trac logo"
            style={{ width:`100%`}} 
          />

          <Img
            fluid={data.firekeepers.childImageSharp.fluid}
            alt="firekeepers casino logo" 
            style={{ width:`100%`}}
          />

          <Img
            fluid={data.redmile.childImageSharp.fluid}
            alt="redmile casino logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.cherae.childImageSharp.fluid}
            alt="sca Promotions logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.coushata.childImageSharp.fluid}
            alt="sca Promotions logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.fsgn.childImageSharp.fluid}
            alt="sca Promotions logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.g2e.childImageSharp.fluid}
            alt="sca Promotions logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.grandtreasures.childImageSharp.fluid}
            alt="sca Promotions logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.jfs.childImageSharp.fluid}
            alt="sca Promotions logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.livecasino.childImageSharp.fluid}
            alt="sca Promotions logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.luckyeagle.childImageSharp.fluid}
            alt="sca Promotions logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.muckleshoot.childImageSharp.fluid}
            alt="muckleshoot logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.naskila.childImageSharp.fluid}
            alt="naskila logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.palms.childImageSharp.fluid}
            alt="palms logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.pearlriver.childImageSharp.fluid}
            alt="pearlriver logo" 
            style={{ width:`100%`}}
          />
           <Img
            fluid={data.planethollywood.childImageSharp.fluid}
            alt="planethollywood logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.risingstar.childImageSharp.fluid}
            alt="risingstar logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.roadhouse.childImageSharp.fluid}
            alt="roadhouse logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.saganing.childImageSharp.fluid}
            alt="saganing logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.saratoga.childImageSharp.fluid}
            alt="saratoga logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.seneca.childImageSharp.fluid}
            alt="seneca logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.soaringeagle.childImageSharp.fluid}
            alt="soaringeagle logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.tachi.childImageSharp.fluid}
            alt="tachi logo" 
            style={{ width:`100%`}}
          />
           <Img
            fluid={data.turningstone.childImageSharp.fluid}
            alt="turningstone logo" 
            style={{ width:`100%`}}
          />
        </ImageWrapper>
        <Sitemap/>
        <div sx={{ m:"0 auto",mt:50,
        width:'100%',alignItems:`center`, textAlign: `center`}}>
         <h4>Touch This Media </h4> 
        <p> <FontAwesomeIcon icon={faAddressBook}/> 3288 Adams Ave <br/>
          P.O. Box 16113 <br/>
          San Diego, CA 92176
        </p>
        <p> <FontAwesomeIcon icon={faPhoneSquareAlt}/> <a href="tel:619-796-2675"> 619-796-2675</a> </p>
        <button sx={{variant: `buttons.toggle`, fontWeight: `semibold`, display: `block`, mx: `auto`}} type="button"> <FontAwesomeIcon icon={faEnvelopeOpenText}/> <a sx={{color:`background`}}href="mailto:sales@touchthismedia.com"> Email Us </a> </button>
        </div>
         
    </div> 

    </div>
    
    </div>
   
        )
   

}



	
export default promo_games




